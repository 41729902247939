import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Button from '../Button';

const nsBase = 'component';
const ns = `${ nsBase }-content-feed`;

const ContentFeed = props => {
	const {
		page,
		showAllItems,
		items,
		nextVisibleAmount,
		itemView,
		loadButton: {
			label,
		},
	} = props;

	let { initialVisibleAmount } = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }__${ page }`]: page,
	});

	// if showAllItems display all items in items array
	if (showAllItems) {
		initialVisibleAmount = items.length;
	}

	const [visible, setVisible] = useState(initialVisibleAmount);
	const [visibleItems, setvisibleItems] = useState([...items]);

	useEffect(() => {
		setvisibleItems([...items]);

		if (items.length < initialVisibleAmount) {
			setVisible(items.length);
		} else {
			setVisible(initialVisibleAmount);
		}
	}, [items, initialVisibleAmount]);

	const handleLoadMore = () => {
		setVisible(prevState => {
			return (prevState + nextVisibleAmount);
		});
	};

	const renderContentFeed = () => {
		return visibleItems.slice(0, visible).map((item, index) => {
			return itemView(item, index);
		});
	};

	return (
		<div className={rootClassnames}>
			{items.length ? (
				<div className={`${ ns }__list`}>
					{renderContentFeed()}
				</div>

			) : (
				<h3 className={`${ ns }__no-items`}>Sorry, we couldn't find any results.</h3> // eslint-disable-line
			)}
			{(visible < visibleItems.length) && (
				<div className={`${ ns }__load-more`}>
					<Button
						className={'btn'}
						variant={`large`}
						onClick={handleLoadMore}
					>
						{label}
					</Button>
				</div>
			)}
		</div>
	);
};

export default ContentFeed;

ContentFeed.defaultProps = {
	showAllItems: false,
	items: [],
	initialVisibleAmount: 6,
	nextVisibleAmount: 6,
	ItemView: () => {
		return <div>Item View Default</div>;
	},
	loadButton: {
		label: 'Load More',
	},
};
