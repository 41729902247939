import React from 'react';
import classNames from 'classnames';

import SEO from '../components/SEO';
import Link from '../components/Link';
import Image from '../components/Image';
import ContentFeed from '../components/ContentFeed';
import Slider from '../components/Slider/Slider';
import SliderArrow from '../icons/slider-arrow';
import ImageTextStacked from '../components/ImageTextStacked';

const nsBase = 'page';
const ns = `${ nsBase }-press`;

const pressFeaturedView = article => {
	const articleImage = article.image;
	let subtitle = null;

	article.tags.map(articleTag => {
		if (articleTag.includes('subtitle::')) {
			// eslint-disable-next-line prefer-destructuring
			subtitle = articleTag.split('::')[1];
		}

		return null;
	});

	return (
		<div className={`${ ns }__featured-list-item`} key={article.handle}>
			<div className={`${ ns }__featured-list-item-image`}>
				<Link href={article.contentHtml}>
					<Image {...articleImage} dataMedia />
				</Link>
			</div>
			<div className={`${ ns }__featured-list-item-content`}>
				<div className={`${ ns }__featured-list-item-eyebrow`}>
					{subtitle && (
						<div className={`${ ns }__featured-list-item-subtitle`}>
							{subtitle}
						</div>
					)}
					<div className={`${ ns }__featured-list-item-date`}>
						{article.publishedAt}
					</div>
				</div>
				<div className={`${ ns }__featured-list-item-title`}>
					<Link href={article.contentHtml}>
						{article.title}
					</Link>
				</div>
				<div className={`${ ns }__featured-list-item-read-more`}>
					<Link href={article.contentHtml}>
						Read more
					</Link>
				</div>
			</div>
		</div>
	);
};

const pressView = article => {
	let subtitle = null;

	article.tags.map(articleTag => {
		if (articleTag.includes('subtitle::')) {
			// eslint-disable-next-line prefer-destructuring
			subtitle = articleTag.split('::')[1];
		}

		return null;
	});

	return (
		<div className={`${ ns }__list-item`} key={article.handle}>
			<div className={`${ ns }__list-item-content`}>
				<div className={`${ ns }__list-item-eyebrow`}>
					{subtitle && (
						<div className={`${ ns }__list-item-subtitle`}>
							{subtitle}
						</div>
					)}
					<div className={`${ ns }__list-item-date`}>
						{article.publishedAt}
					</div>
				</div>
				<div className={`${ ns }__list-item-title`}>
					{article.title}
				</div>
				<div className={`${ ns }__list-item-read-more`}>
					<Link href={article.contentHtml}>
						Read more
					</Link>
				</div>
			</div>
		</div>
	);
};

const PressPage = ({ pageContext }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		featuredArticles,
		articles,
	} = pageContext;

	return (
		<div className={rootClassnames}>
			<SEO title={'Press'} />
			<div className={'container-fluid'}>
				<h1 className={`${ ns }__title`}>
					Violux in the news.
				</h1>
			</div>
			{featuredArticles.length > 0 && (
				<div className={`${ ns }__featured-articles`}>
					<div className={'container-fluid'}>
						<div className={`${ ns }__featured-articles-title h3`}>
							Featured Press
						</div>
						<ContentFeed
							page={'press-featured-articles'}
							items={featuredArticles}
							itemView={pressFeaturedView}
							// initialVisibleAmount={4}
							// nextVisibleAmount={4}
							showAllItems
						/>
					</div>
				</div>
			)}
			<Slider
				modifier={'press-slider'}
				slides={[
					{
						image: {
							mainImageMobile: '/assets/images/mensjournal.png',
							altText: 'Mens Journal',
						},
						description: '“Living life like The Jetsons is closer than we think.”',
					},
					{
						image: {
							mainImageMobile: '/assets/images/family-handyman-logo.png',
							altText: 'Family Handyman logo',
						},
						description: '“The products are incredibly easy to use — just put your items inside, close the door, hit a button and within three minutes, you’re done.”',
					},
					{
						image: {
							mainImageMobile: '/assets/images/uci-logo.png',
							altText: 'UCI logo',
						},
						description: '“Unlike other methods of disinfection or sanitization, such as bleach and alcohol wipes, Violux products are chemical free and safe for disinfecting food.”',
					},
				]}
				sliderSettings={{
					speed: 700,
					prevArrow: <SliderArrow direction={'back'} />,
					nextArrow: <SliderArrow />,
					responsive: [
						{
							breakpoint: 992,
							settings: {
								arrows: false,
								dots: true,
								infinite: true,
								slidesToShow: 1,
								slidesToScroll: 1,
							},
						},
						{
							breakpoint: 9999,
							settings: {
								arrows: true,
								dots: true,
								infinite: true,
								centerMode: true,
								slidesToShow: 1,
								slidesToScroll: 1,
								// centerPadding: '150px',
							},
						},
					],
				}}
			/>
			<div className={`${ ns }__articles`}>
				<div className={'container-fluid'}>
					<div className={`${ ns }__articles-title h3`}>
						Recent Press
					</div>
					<ContentFeed
						page={'press-articles'}
						items={articles}
						itemView={pressView}
						initialVisibleAmount={5}
						nextVisibleAmount={5}
						// showAllItems
					/>
				</div>
			</div>
			<ImageTextStacked
				modifier={'press'}
				hTag={'h3'}
				heading={'Press Contact'}
				description={'If you’re interested in learning more about Violux, please reach out to our team at <a href="mailto:hello@violux.com">hello@violux.com</a>'}
			/>
		</div>
	);
};

export default PressPage;
