import React from 'react';

const SliderArrow = ({
	direction,
	currentSlide,
	slideCount,
	...props
}) => {
	return (
		/* eslint-disable-next-line */
		<div {...props}>
			<svg style={{ transform: direction === 'back' ? 'none' : 'rotate(180deg)' }} width={'56px'} height={'56px'} viewBox={'0 0 56 56'} version={'1.1'}>
				<g id={'PAGES'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
					<g id={'LUMA_PDP_PRO'} transform={'translate(-74.000000, -7014.000000)'}>
						<g id={'Group-4'} transform={'translate(74.000000, 7014.000000)'}>
							<circle id={'Oval-Copy'} fill={'#B5D9F2'} cx={'28'} cy={'28'} r={'28'} />
							<polygon id={'Fill-1'} fill={'#003370'} points={'27.485 20 20.414 27.071 19 28.485 27.485 36.97 28.899 35.557 22.827 29.485 37.034 29.485 37.034 27.485 22.827 27.485 28.899 21.414'} />
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default SliderArrow;
